import Vue from 'vue'
import Vuex from 'vuex'
import fb from './plugins/firebase'
const firebase = fb.firebase
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const fbUserPropsExtractor = (user) => {
  let {uid, displayName, email, photoURL, metadata = null, lastSignInTime = null} = user
  if (lastSignInTime === null && metadata !== null) {
    lastSignInTime = metadata.lastSignInTime
  }
  return {uid, displayName, email, photoURL, lastSignInTime}
}

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: 'inkie-classes'
  })],
  state: {
    user: null,
    classes: [],
    loading: {
      action: null
    },
    editing: false
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user
    },
    loading: (state, data) => {
      state.loading = data
    },
    doneLoading: state => {
      state.loading = null
    },
    setClasses: (state, data) => {
      state.classes = data
    },
    setClassUnits: (state, {classId, units}) => {
      const index = state.classes.findIndex(cls => cls.uid === classId)
      if (index !== -1) {
        state.classes[index].units = units
        state.classes[index].unitsFetched = new Date().getTime()
      }
    },
    setClassUnitModules: (state, {classId, unitId, modules}) => {
      const cIndex = state.classes.findIndex(cls => cls.uid === classId)
      if (cIndex !== -1 && state.classes[cIndex].units) {
        const uIndex = state.classes[cIndex].units.findIndex(u => u.uid === unitId)
        if (uIndex !== -1) {
          state.classes[cIndex].units[uIndex].modules = modules
        }
      }
    },
    toggleEditing: (state) => {
      state.editing = !state.editing
    }
  },
  actions: {
    signin: async ({commit, dispatch}, {email, password}) => {
      let errored = null
      commit('loading', {action: 'signin', processing: true})
      const res = await firebase.auth().signInWithEmailAndPassword(email, password).catch(e => {
        commit('loading', {action: 'signin', failed: true, error: e.message})
        errored = {signedIn: false, error: e.message}
      })
      if (!errored) {
        const {user} = res
        if (user) {
          const usrData = fbUserPropsExtractor(user)
          commit('setUser', usrData)
          commit('doneLoading')
          dispatch('getExtraUserData')
          return {signedIn: true}
        } else {
          commit('doneLoading')
          return {signedIn: false, error: 'Unknown error occurred'}
        }
      }
      commit('doneLoading')
      return errored
    },
    getExtraUserData: async ({commit, state, dispatch}) => {
      let extraData, usrData, errored
      commit('loading', {action: 'userData', processing: true})
      try {
        extraData = await firebase.firestore().collection('users').doc(state.user.uid).get()
        usrData = await extraData.data()
      } catch (e) {
        commit('loading', {action: 'getUserData', failed: true, error: e.message})
        errored = {error: e.message, signedIn: true}
      }
      if (errored) {
        return errored
      }
      usrData.classes = usrData.classes.map(cls => cls.hasOwnProperty('path') ? cls.path : cls)
      commit('setUser', {...fbUserPropsExtractor(state.user), ...usrData})
      commit('doneLoading')
      dispatch('getUsersClasses')
    },
    getUsersClasses: async ({commit, state, dispatch}) => {
      console.log('getting user classes')
      commit('loading', {action: 'getUserClasses', processing: true})
      let classes = []
      if (state.user.classes) {
        for (let clsId of state.user.classes) {
          let cls
          try {
            if (typeof clsId !== 'string') {
              clsId = `classes/${clsId.key}`
            }
            cls = await firebase.firestore().doc(clsId).get()
            cls = cls.data()
          } catch (e) {
            console.error(`Couldn't get class ${clsId} from user: ${e.message}`)
          }
          if (!cls) {
            continue
          }
          classes.push({uid: clsId.replace('classes/', ''), ...cls})
        }
      }
      if (classes.length) {
        commit('setClasses', classes)
        for (let cl of classes) {
          await dispatch('getClassUnits',cl.uid)
        }
      }
      commit('doneLoading')
    },
    getClassUnits: async ({commit, dispatch}, classId) => {
      commit('loading', {action: 'getClassUnits', processing: true})
      let classUnits = []
      let units = await firebase.firestore().collection(`classes/${classId}/units`).get()
      units = units.docs
      for (let unit of units) {
        classUnits.push({...unit.data(), uid: unit.id})
      }
      classUnits = classUnits.filter(u => !u.status || u.status === "publish").map(unit => ({
        slug: unit.slug,
        name: unit.title,
        uid: unit.uid,
        order: unit.order || 1
      })).sort((a,b) => a.order - b.order)
      commit('setClassUnits', {classId, units: classUnits})
      for (let unit of classUnits) {
        dispatch('getClassUnitModules', {classId, unitId: unit.uid})
      }
      commit('doneLoading')
    },
    getClassUnitModules: async ({commit}, {classId, unitId}) => {
      commit('loading', {action: 'getClassUnitModules', processing: true})
      let modulesList = []
      let modules = await firebase.firestore().collection(`classes/${classId}/units/${unitId}/modules`).get()
      modules = modules.docs
      for (let module of modules) {
        modulesList.push({...module.data(), uid: module.id})
      }
      modulesList = modulesList.filter(mod => mod.status === "publish")
      commit('setClassUnitModules', {classId, unitId, modules: modulesList})
      commit('doneLoading')
    },
    logout: async ({commit}) => {
      commit('setUser', null)
      commit('setClasses', [])
      await firebase.auth().signOut()
    }
  },
  getters: {
    user: state => state.user,
    classes: state => state.classes,
    classData: state => id => state.classes.find(c => c.uid === id),
    units: state => id => state.classes.find(c => c.uid === id).units || [],
    loading: state => state.loading,
    isAdmin: state => state.user.hasOwnProperty('roles') && state.user.roles.length && state.user.roles.includes('admin'),
    isEditing: state => state.editing
  }
})
