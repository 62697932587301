import Vue from 'vue'
import './plugins/axios'
import firebase from './plugins/firebase'
import App from './App.vue'

Vue.use(firebase)

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

firebase.firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    store.state.user = user
    store.dispatch('getExtraUserData')
  } else {
    store.state.user = null
    store.commit('setClasses', [])
  }
});

Vue.use(vuetify)

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify
}).$mount('#app')
