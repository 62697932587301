<template>
  <v-navigation-drawer class="teal accent-4" dark app clipped v-model="draw">
    <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menu
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <v-divider/>
    <v-list dense nav class="py-0">
      <v-list-item
          v-for="item in items"
          :key="item.path"
          link
          :to="item.path"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
        <v-divider/>
    <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Classes
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <v-list dense nav class="py-0">
      <v-list-item
          v-for="item in classes"
          :key="item.path"
          link
          :to="item.path"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2" v-if="user">
        <v-btn block @click="logout">Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'Navigation',
    data: () => ({
      drawer: false,
    }),
    computed: {
      user () {
        return this.$store.getters.user
      },
      items () {
        if (!this.user) {
          return [{
            path: '/signin',
            label: 'SignIn',
            icon: 'account'
          }]
        }
        return [
          {
            path: '/',
            label: 'My Classes',
            icon: 'mdi-view-list'
          },
          {
            path: '/account',
            label: 'Account',
            icon: 'mdi-account'
          }
        ]
      },
      classes () {
        return this.$store.getters.classes.map(cl => {
          return  {
            path: `/classes/${cl.slug}`,
            label: cl.name,
          }
        })
      }
    },
    mounted() {
      this.drawer = this.draw
    },
    props: {
      draw: Boolean
    },
    methods: {
      logout () {
        this.$store.dispatch('logout')
        this.$router.push('/signin')
      }
    },
    watch: {
      draw (val) {
        this.$emit('draw', val)
      }
    }
  }
</script>
