<template>
  <v-container>
    <v-card flat color="pink lighten-3" outlined>
      <v-card-title>Your Classes</v-card-title>
      <v-list dense nav class="py-0">
        <v-list-item v-for="cls in classes" :key="cls.uid" class="class" :to="'/classes/'+cls.slug">
          <v-list-item-title><h3>{{cls.name}}</h3></v-list-item-title>
          <v-list-item-content v-if="cls.tags && cls.tags.length"><p>{{cls.tags.join(', ')}}</p></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'classes',
    computed: {
      classes () {
        return this.$store.getters.classes
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../styles/variables.scss';

  .class {
    padding: $dim-gutter;
  }
</style>
