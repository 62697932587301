<template>
  <v-col>
    <v-card :loading="loading">
      <v-card-text v-if="classes">
        <classes />
      </v-card-text>
      <v-card-text v-else>
          No classes
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="refresh">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>

  import Classes from './classes'
  export default {
    name: 'Home',
    components: {Classes},
    data: () => ({
      loading: false
    }),
    computed: {
      classes () {
        return this.$store.getters.classes
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      refresh () {
        this.loading=true
        this.$store.dispatch('getUsersClasses').then(() => {
          this.loading=false
        })
      }
    }
  }
</script>
