import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
require('./plugins/firebase')
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import(/* webpackChunkName: "signin" */ './views/signin.vue')
    },
    {
      path: '/pass-reset',
      name: 'passReset',
      component: () => import(/* webpackChunkName: "pass-reset" */ './views/pass-reset.vue')
    },
    {
      path: '/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "account" */ './views/account.vue')
    },
    {
      path: '/classes/:slug',
      name: 'class',
      component: () => import(/* webpackChunkName: "class" */ './views/Class.vue')
    },
    {
      path: '/classes',
      name: 'classes',
      component: () => import(/* webpackChunkName: "class" */ './views/classes.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!store.getters.user && (to.name !== 'signin' && to.name !== 'passReset')) { // not signed in
    next({ name: 'signin' })
  } else if (store.getters.user && from.name === 'signin') { // just signed in / already signed in
    next()
  } else if (store.getters.user && (to.name === 'signin' || to.name === 'passReset') ) { // already signed in
    next({name: 'account'})
  } else {
    next()
  }
})

export default router
