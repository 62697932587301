import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
firebase.initializeApp(require('../firebase.config'))

const fbPlugin = {
  firebase,
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      firebase: {
        get () {
          return firebase
        }
      },
      $firebase: {
        get () {
          return firebase
        }
      },
      $fb_storage: {
        get () {
          return firebase.storage()
        }
      }
    })
  }
}

Vue.use(fbPlugin)

export default fbPlugin
