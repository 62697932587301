<template>
  <v-app id="inspire">
    <navigation :draw="drawer" @draw="updateDrawer"/>
    <v-app-bar app clipped-left color="#73eae5" dark shrink-on-scroll prominent fade-img-on-scroll src="@/assets/goldGlitterBackground.jpg">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>Inkie Quill Classes</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="toggleEditing()" :color="isEditing ? 'teal accent-2' : 'blue-grey'">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon :to="{name: 'account'}">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <router-view/>
    </v-content>

    <v-footer app>
      <span v-html="copyright"/>
    </v-footer>
  </v-app>
</template>

<script>
  import Navigation from './components/navigation'

  export default {
    components: { Navigation },
    props: {
      source: String
    },
    data: () => ({
      copyright: `Copyright Inkie Quill &copy; ${ new Date().getFullYear() }`,
      drawer: false
    }),
    computed: {
      isAdmin () {
        return this.$store.getters.isAdmin
      },
      isEditing () {
        return this.$store.getters.isEditing
      }
    },
    methods: {
      updateDrawer (val) {
        this.drawer = val
      },
      toggleEditing () {
        if (this.$store.getters.isAdmin) {
          this.$store.commit('toggleEditing')
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/main.scss';
</style>
